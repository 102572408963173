footer {
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  text-align: left;
  background-color: var(--color-additional);
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

.logo {
  font-size: 2rem;
  color: var(--color-primary);
}
