.component {
  max-width: 1170px;
}

.input {
  width: 100%;
  margin-bottom: 40px;
  padding: 20px;
  border: none;
  border-bottom: 1px solid var(--color-primary);
  background-color: var(--color-line);
  color: var(--color-accent);
  font-size: 1.5rem;
}
