header {
  width: 100%;
  height: 60px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
  background-color: transparent;
  /* position: fixed;
  top: 0;
  left: 0;
  z-index: 999; */
  position: relative;
  z-index: 1000;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

.logo {
  font-size: 2rem;
  color: var(--color-primary);
}
