.component {
  width: 100vw;
  height: 100vh;
  padding-top: 120px;
  background: url(/src/images/netflix-library-photo-scaled.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 0;
}
.component::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: 3rem;
  color: var(--color-accent);
}
