.checkbox__item {
  display: inline-block;
  margin: 0 5px 0 0;
  user-select: none;
  position: relative;
}
.checkbox__item input {
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}
.checkbox__item span {
  display: inline-block;
  cursor: pointer;
  padding: 0px 10px;
  line-height: 30px;
  border: none;
  transition: background 0.2s ease;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--color-additional);
}

/* Checked */
.checkbox__item input:checked + span {
  color: var(--color-primary);
}

@media (max-width: 390px) {
  .checkbox__item span {
    font-size: 1.1rem;
  }
}
