.firstscreen {
  margin-top: -60px;
  padding-top: 120px;
  padding-bottom: 60px;
  background: url(/src/images/1_5lyavS59mazOFnb55Z6znQ.png) no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 0;
}
.firstscreen::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.component {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  z-index: 1;
}

.title {
  margin-bottom: 30px;
  text-align: left;
  font-size: 2rem;
  color: var(--color-accent);
}

.text {
  text-align: left;
  margin-right: 15px;
  color: var(--color-accent);
  font-size: 1.5rem;
  font-weight: 600;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

@media (max-width: 520px) {
  .flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .checkbox {
    margin-bottom: 15px;
  }
}

@media (max-width: 390px) {
  .text {
    font-size: 1rem;
  }
}
