.component {
  margin: 10px;
  padding: 5px;
  width: 30%;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.image {
  width: 100%;
}

.content {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  color: var(--color-additional);
}

.title {
  margin-bottom: 10px;
  text-align: left;
  width: 65%;
  font-size: 1.4rem;
}

.text {
  width: 30%;
  padding: 5px;
  font-size: 1rem;
  border: 1px solid var(--color-additional);
}

.footer {
  font-size: 1rem;
  text-align: left;
}

.genres,
.overview,
.vote {
  margin-bottom: 20px;
}

.genres {
  font-size: 1rem;
}

@media (max-width: 935px) {
  .component {
    width: 45%;
  }
}

@media (max-width: 635px) {
  .component {
    width: 95%;
  }
}
