.app {
  text-align: center;
}

.app {
  position: fixed; /*Отсоединяем контейнер от страницы и фиксируем в окне браузера*/
  width: 100%; /*Ширина на все окно браузера*/
  height: 100%; /*Высота на все окно браузера*/
  display: flex; /*Превращаем контейнер в flexBox*/
  flex-direction: column; /*Расставляем дочерние элементы по вертикали*/
}

.wrap {
  overflow-y: auto; /*Вертикальная полоса прокрутки для текста*/
  text-align: justify; /*выравнивание текста по левому и правому краям*/
  flex-grow: 1; /*Предписываем занять все свободное пространство*/
}

.footer {
  flex-grow: 0; /*Запрещаем увеличиваться*/
  flex-shrink: 0; /*Запрещаем сжиматься*/
  flex-basis: auto;
}
